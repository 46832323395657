import React from 'react';
import styled from 'styled-components';

import { Box, Grid } from '@core';
import { TextBlock } from '@components';
import { ImageCore } from '@components/image';

const Image = styled(ImageCore)`
  align-self: flex-start; // to keep image aspect ratio on safari
`;

const Headline = ({ title, description, textWidth, image, imageHeight, padding }) => (
  <Box {...padding}>
    <Grid.Container>
      <Grid.Row
        justifyContent="center"
        px={{ _: 24, md: 40 }}
        maxWidth={textWidth === 'narrow' && 880}
      >
        {(title || description) && (
          <TextBlock title={title} description={description} textAlign="center" />
        )}
        {image && (
          <Grid.Col mt={24} display="flex" justifyContent="center">
            <Image src={image} alt={title} maxHeight={imageHeight} />
          </Grid.Col>
        )}
      </Grid.Row>
    </Grid.Container>
  </Box>
);

export default Headline;
